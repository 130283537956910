/* Lineup.css */
.lineup-page {
    padding: 2rem;
    padding-top: 80px;
}

.lineup-container h1{
  max-width: 1200px;
  margin: auto;
  padding-top: 24px;
  color: #ecba1f
}
.lineup-container{
  /* width: fit-content; */
  max-width: 1200px;
    margin: auto;
    background: rgba(21, 21, 21, 0.7);
    z-index: 1;
    position: relative;
    margin-top: 32px;
    backdrop-filter: blur(2px);
}
  
  .artist-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1200px;
    margin: auto;
    gap: 20px;
    margin-top: 2rem;
    z-index: 10;
    position: relative;
    
    padding: 24px 0;
  }
  
  .artist-card {
    cursor: pointer;
    text-align: center;
    width: 260px;
    }

  .artist-card-container{
    height: 260px;
    width: 100%;
    overflow: hidden;
  }

  .artist-card:hover > .artist-card-container img {
    scale: 1.1;
  }
  .artist-card:hover > .artist-name {
    color: #edbb20;
  }
  
  .artist-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.4s ease-in-out;
  }
  
  .artist-name {
    margin-top: 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  /* Popup Modal Styles */
  .artist-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
  }
  
  .popup-content {
    background: #1D1D1D;
    padding: 0 20px 20px 20px;
    width: 400px;
    max-width: 90%;
    text-align: center;
    position: relative;
    max-height: 75svh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .popup-image {
    width: 100%;
  }
  
  .popup-content h2 {
    margin-top: 1.5rem;
    font-size: 1.8rem;
  }
  
  .popup-content p {
    margin-top: 1rem;
    font-size: 1rem;
  }
  
  .button-container{
    position: relative;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid white;
    background: #1D1D1D;
    margin-top: 4px;
    margin-bottom: 12px;
  }
  .close-button {
    position: relative;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: white;
  }

  .close-button:hover{
    color: #edbb20
  }
  
  .media-embed iframe {
    margin-top: 1rem;
    border-radius: 8px;
  }
  

  
@media only screen and (max-width: 603px) {

  .lineup-page{
    padding: 0 5%;
    width: 90%;
  }

  .artist-image{
    width: 350px;
    height: 350px;
  }

  .lineup-container{
    margin-top: 64px;
  }

  .lineup-container h1 {
    padding-top: 20px;
  }
  .artist-card-container,
  .artist-card
  {
    aspect-ratio: 1;
    height: 100%;
    width: 100%;
  }

  .artist-card-container{
    width: 96%;
    margin: 2%;
    padding: 0;
  }


  .artist-grid{
    margin-top: 0;
  }


  .artist-grid{
    gap: 96px;
  }

  .lineup-container{
    padding-bottom: 64px;
    margin-bottom: 32px;
  }

  .artist-popup{
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }

  .artist-card:hover > .artist-card-container img {
    scale: 1;
  }

  
}