/* Footer container styling */
.footer {
    background-color: rgba(21, 21, 21, 0.95);
    backdrop-filter: blur(2px);
    padding: 8px;
    position: relative;
    bottom: 0;
    
  }

  .home-page-page .footer{
    scroll-snap-align: start;
  }
  
  /* Container to keep content aligned */
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Left section styling (for the logo) */
  .footer-left {
    /* flex: 1; */
  }
  
  .footer-logo {
    height: 50px;
  }
  
  /* Right section styling (for the links and privacy statement) */
  .footer-right {
    flex: 2;
    text-align: right;
  }
  
  /* Footer links container */
  .footer-links {
    margin-bottom: 10px;
  }
  
  /* Styling for each footer link */
  .footer-link {
    margin-left: 15px;
    text-decoration: none;
    color: white;
    font-size: 12px;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  /* Privacy statement text styling */
  .footer-privacy {
    font-size: 12px;
    color: #666;
  }

  @media screen and (max-width: 768px) {
    .footer{
        padding-bottom: 12px;
    }
  }
  