@font-face {
  font-family: 'CuteDino';
  src: local('CuteDino'), url(./fonts/CuteDino.woff) format('woff');
}
@font-face {
  font-family: 'BoomerTantrum';
  src: local('BoomerTantrum'), url(./fonts/BoomerTantrum.woff2) format('woff2');
}

html{
  overflow: hidden;
}

.App {
  text-align: center;
  color: #1D1D1D;
  background-color: #1D1D1D;
  scroll-snap-type: y mandatory;
  overflow-y: scroll; /* Only vertical scrolling */
  overflow-x: hidden;
  scrollbar-width: none;
  height: 100%; /* Full viewport height */
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  overscroll-behavior-y: contain; /* Prevent scroll chaining */
}

h1,
h2,
h3,
h4,
h5,
p,
a,
label,
input,
span,
button {
  color: white;
  /* font-family:'Courier New', Courier, monospace; */
  font-family:'BoomerTantrum', Courier, monospace;
}

h3{
  letter-spacing: 1px;
  line-height: 26px;
}

h4{
  line-height: 20px;
}

.section {
  /* height: 100vh; Full viewport height for each section */
  scroll-snap-align: start; /* Snaps to the start of each section */
  flex-shrink: 0; /* Prevents sections from shrinking */
}

.courier{
  font-family:'Courier New', Courier, monospace !important;
}

.highlight-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  pointer-events: none;
  transition: transform 0.5s ease, width 0.5s ease, height 0.5s ease;
  z-index: 99; /* Behind the text */
  transform-origin: center; /* Ensures the SVG rotates around its center */
  opacity: 0.5;
  min-width: 84px;
}

.inline-link{
  text-decoration: none;
  width: fit-content;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.inline-link:hover{
  color:#edbb20;
}

.inline-link:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ecba1f;
  left: 0;
  top: 15px;
}
.inline-link.courier:after{
  top: 17px;
}

.inline-link.big-link:after{
  top: 28px;
}

.inline-link.big-link{
  font-size: 1.5em;
}

.nav-link {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.nav-link:hover{
  color: #edbb20;
}

.nav-link.active {
  color: #edbb20; /* Adjust text color if needed */
  font-weight: bold;
}

.nav-link-home-logo{
  padding-left: 24px;
  font-size: 18px;
}

.nav-link-highlight{
  /* background: #edbb20; */
  border: 1px solid white;
  /* color: black !important; */
  font-weight: 700;
  transition: all 0.5s ease-in-out;
}
.nav-link-highlight:hover{
  color: #edbb20 !important;
  /* border: 1px solid #edbb20; */
}

.link-highlight{
  border: 1px solid white;
  padding: 10px 15px;
  font-weight: 700;
  transition: all 0.5s ease-in-out;
  font-size: 1.2em;
  text-decoration: none;
}
.link-highlight:hover{
  color: #edbb20 !important;
}

.hero-container, 
.about-section,
.tickets-section, 
.info-section, 
.merch-section {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjust logo container for responsiveness */
.logo-container {
  max-width: 100%;
  overflow: visible;
  margin: 0 auto;
  transition: 1.5s all ease-in-out;
}

/* Adjust other sections' containers to fit within 100vh */
.hero, .hero-container {
  min-height: 100%;
}

/* Remove overflow for inner elements */
.hero-container, .body-container {
  overflow: hidden;
}

/* Adjust blobs to prevent horizontal overflow */
.blob-container-left,
.blob-container-right {
  width: auto;
  height: auto;
}

.hero {
  /* background-image: url('wlbg.jpeg');*/
  background-size: cover;
  background-position: center;
  background: #1D1D1D;
  /* background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(224,162,245,1) 33%, rgba(60,4,77,1) 67%, rgba(60,4,77,1) 100%); */
  min-height: 100svh;
  width: 100%;
  margin: auto;
  overflow: visible;
}

.logo-content {
  background-image: url('wastelands-logo-no-bgwebp.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 340px;
  width: 880px;
  z-index: 2;
  position: relative;
  /* top: 50%;
  transform: translateY(-50%); */
  /* transition: 1.5s all ease-in-out; */
}

@supports not (background-image: url('image.webp')) {
  .logo-content {
    background-image: url('wastelands-logo-no-bgwebp.webp');
  }
}

.hero-container {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  height: 100svh;
  background: #1D1D1D;
}

.hero-text-container {
  z-index: 2;
  position: relative;
  width: fit-content;
  transition: all 1s ease-out;
  
}
.hero-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
    padding: 0px 12px;
    width: fit-content;
    margin: 0 auto;
    background:rgba(29, 29, 29, 0.3)
}

.hero-text-container h2 {
  z-index: 3;
  position: relative;
  color: white;
  font-size: 1.6em;
  letter-spacing: 2px;
  /* font-family: 'CuteDino'; */
}
.hero-text-container h3 {
  max-width: 600px;
  margin: auto;
}

.hero-text-container-bottom{
  padding-bottom: 32px;
  padding-top: 32px;
  margin: auto;
  backdrop-filter: blur(10px);
    padding: 12px 0px;
    width: fit-content;
    margin: 0 auto;
    background:rgba(29, 29, 29, 0.3)
}
/* 
.hero-text-container:after {
  content: '';
  background-image: url('Highlight.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 334px;
  height: 89px;
  z-index: 2;
  position: absolute;
  top: 0px;
  left: -82px;
} */

.hero h1 {
  font-size: 4em;
  margin: 0.5em 0;
}

.hero p {
  font-size: 1.2em;
  max-width: 100%;
}

.hero-text-container-link{
  margin-top: 30px;
}
.hero-text-container-link a {
  /* color: #ecba1f; */
}
.links {
  position: absolute;
  width: 100%;
  height: 60px;
  top: 0;
  z-index: 99999;
  background-color: rgba(29, 29, 29, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.links-container{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
}

.links a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1.2em;
  transition: color 0.3s;
}

.links a:hover {
  /* color: #6d26d2; */
}

/* Show hamburger menu on small screens */
@media (max-width: 768px) {
  .hamburger {
    display: flex !important;
  }

  .links{
    height: 64px;
  }

  .links-container {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: rgba(29, 29, 29, 0.95);
    width: 100%;
    height: calc(100vh - 80px);
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }

  .links-container.active {
    display: flex;
  }

  .links a {
    margin: 10px 0;
    font-size: 1.5em;
  }
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  /* margin-right: 20px; */
  position: fixed;
  right: 5%;
  z-index: 1;
}

.hamburger span {
  width: 30px;
  height: 4px;
  background-color: #ecba1f;
  margin: 3px 0;
  transition: 0.3s;
  border-radius: 1px;
}
/* Animation when the hamburger menu is open */
.hamburger.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg) translate(8.5px, -8.5px)
}

.links-container.active{
  position: fixed;
  top: 0;
  height: 100vh;
  backdrop-filter: blur(2px);
}


.hero-links {
  bottom: 36px;
  position: fixed;
    transform: translateX(-50%);
    left: 50%;
    z-index: 100;
}

.hero-links span {
  z-index: 2;
  text-decoration: none;
  padding: 12px 24px;
  /* font-family: 'CuteDino'; */
  font-size: 22px;
  /* transition: all 0.5s; */
  color: white;
  cursor: pointer;
  position: relative;
}

.hero-links:after {
  content: '';
  background-image: url('Circle.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 196px;
  height: 204px;
  z-index: 1;
  position: fixed;
  bottom: -140px;
  transform: translateX(-50%);
  left: 50%;
  /* transition: all 0.5s ease-in-out; */
}

.hero-link-clicked:after {
  bottom: -250px !important;
}

.hero-links:hover:after {
  bottom: -100px;
}


.about-section {
  position: relative;
  top: 0;
  width: 100%;
  opacity: 1;
  transition: all 2s ease-in;
  height: 100svh;
}

.body-container {
  top: 250px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.body-header {
  max-width: 800px;
  width: fit-content;
}

.body-header h2 {
  margin-top: 0px;
  z-index: 3;
  position: relative;
  /* font-family: 'CuteDino'; */
  font-weight: 400 !important;
}

/* .body-header:before {
  content: '';
  background-image: url('Highlight.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 334px;
  height: 89px;
  z-index: 2;
  position: absolute;
  top: -32px;
  left: -7px;
} */


.body-wrapper {
  padding-top: 12px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  position: relative;
  backdrop-filter: blur(10px);
  background: rgba(29, 29, 29, 0.3)
}


.about-image-container,
.about-text-container {
  max-width: 1000px;
  margin: auto;
}

.about-image-container {
  width: 100%;
}

.about-text-container {
  max-width: 490px;
  text-align: center;
  padding: 12px 3%;
}

.about-text-container:first-of-type {
  margin-top: 0;
}

.about-text-container:nth-of-type(2) {
  margin-top: 80px;
}

.blob-container-right {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 33%;
  max-width: 600px;
  top: 50%;
  transform: translatey(-50%);
  min-height: 1080px;
  max-height: 1080px;
  pointer-events: none;
}

.blob-container-left {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 33%;
  max-width: 600px;
  top: 50%;
  transform: translatey(-50%);
  min-height: 1080px;
  max-height: 1080px;
  pointer-events: none;
}

.blob-mask{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  top: 50%;
  transform: translatey(-50%);
  background: black;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.App:has(.section.active:not(.hero.active)) .blob-container .blob-mask{
  /* opacity: 0.3; */
}

.blob {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
}

/* RIGHT */

.blob_snowflake {
  background-image: url('Snowflake_Animate.svg');
  width: 270px;
  height: 300px;
  top: 102px;
  right: 0;
  animation: blob-spin 3s linear;
  animation-iteration-count: infinite;
}

.blob_doublestripe {
  background-image: url('DoubleStripe.svg');
  height: 324px;
  width: 242px;
  top: -100px;
  right: 200px;
}

.blob_arrowsquiggle {
  background-image: url('ArrowSquiggle.svg');
  width: 226px;
  height: 172px;
  position: absolute;
  top: 180px;
  right: 225px;
}

.blob_arrowbent {
  background-image: url('ArrowBent.svg');
  width: 342px;
  height: 342px;
  z-index: 2;
  top: 285px;
  right: 50px;
}

.blob_longsquiggle {
  background-image: url('LongSquiggle.svg');
  width: 92px;
  height: 474px;
  z-index: 2;
  top: 285px;
  right: 4px;
}

.blob_mellowsquiggle {
  background-image: url('MellowSquiggle.svg');
  width: 312px;
  height: 242px;
  z-index: 2;
  top: 620px;
  right: 100px;
}

.blob_waves {
  background-image: url('Waves.svg');
  width: 384px;
  height: 293px;
  z-index: 2;
  top: 773px;
  right: 0px;
  animation: shimmy 5s linear;
  animation-iteration-count: infinite;
}

.blob_zigzag {
  background-image: url('Zigzag.svg');
  width: 365px;
  height: 365px;
  z-index: 2;
  top: 718px;
  right: 312px;
}

.blob_circle {
  background-image: url('Circle.svg');
  width: 196px;
  height: 160px;
  z-index: 2;
  bottom: -38px;
  right: 615px;
}

.about-bg {
  opacity: 0;
  width: 100%;
  height: 100svh;
  background-color: #1D1D1D;
  position: relative;
}

.body-end {
  position: relative;
  z-index: 4;
}

.about-text-header h4 {
  margin-top: 0;
  font-size: 20px;
}

@media only screen and (max-width: 1160px) {
  .blob_circle {
    display: none;
  }

  

  .body-container {
    top: 250px;
    width: 100%;
  }

  .about-text-container {
    text-align: center;
    padding: 12px;
  }

  .about-text-container:nth-of-type(2) {
    margin-top: 0px !important;
  }

  

}









/* LEFT  */

.blob_bigsquiggle {
  background-image: url('BigSquiggle.svg');
  width: 384px;
  height: 523px;
  z-index: 2;
  top: 60px;
  left: 111px;
}

.blob_arrowstraight {
  background-image: url('ArrowStraight.svg');
  width: 123px;
  height: 183px;
  z-index: 2;
  top: 187px;
  left: 25px;
  animation: vertical-shimmy 3s linear;
  animation-iteration-count: infinite;
}

.blob_dot {
  background-image: url('Dot.svg');
  width: 125px;
  height: 142px;
  z-index: 2;
  top: 435px;
  left: 26px;
}

.blob_doublezag {
  background-image: url('DoubleZag.svg');
  width: 315px;
  height: 181px;
  z-index: 2;
  top: 624px;
  left: 18px;
}

.blob_bendlong {
  background-image: url('BendLong.svg');
  width: 399px;
  height: 472px;
  z-index: 2;
  top: 476px;
  left: 235px;
}

.blob_dubbend {
  background-image: url('DubBend.svg');
  width: 372px;
  height: 215px;
  z-index: 2;
  top: 857px;
  left: 12px;
  animation: shimmy-left 4s linear;
  animation-iteration-count: infinite;
}

.blob_circle_left {
  background-image: url('Circle.svg');
  width: 196px;
  height: 160px;
  z-index: 2;
  top: -18px;
  left: 305px;
}


@keyframes blob-spin {
  0% {
    transform: rotate(0)
  }

  50% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes vertical-shimmy {
  0% {
    top: 187px;
    transform: rotate(0)
  }

  20% {
    top: 203px;
    transform: rotate(10deg);
  }

  40% {
    top: 187px;
    transform: rotate(0);
  }

  100% {
    top: 187px;
    transform: rotate(0);
  }
}

@keyframes shimmy {
  0% {
    right: 0px;
  }

  20% {
    right: -60px;
  }

  40% {
    right: 0px;
  }

  100% {
    right: 0px;
  }
}

@keyframes shimmy-left {
  0% {
    left: 0px;
  }

  50% {
    left: 30px;
  }

  100% {
    left: 0px;
  }
}

/* Info.css */
.info.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  padding: 20px;
}

#info .container {
  width: 100%;
  height: 100%;
  max-width: 800px;
  padding: 20px;
  padding-bottom: 0;
  margin: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

#info .header h2 {
  text-align: center;
}

.info-subheader{
  text-align: left;
  padding: 0 2.5%;
  
  max-width: 360px;
}

/* #info{
  padding-bottom: 102px;
} */

.dropdown-container {
  width: 95%;
  padding: 2.5%;
  padding-bottom: 0;
  /* background-color: rgba(29, 29, 29, 0.7); */
  /* backdrop-filter: blur(2px); */
}

.dropdown {
  margin-bottom: 20px;
  border-bottom: 1px solid white; /* White border underneath each dropdown */
}

.dropdown-header {
  display: flex;
  justify-content: space-between; /* Ensure chevron is on the right side */
  align-items: center;
  padding: 15px;
  cursor: pointer;
}

.dropdown-header h3 {
  margin: 0;
  font-size: 18px;
  text-align: left;
}
.dropdown-header:hover h3,
.dropdown-header:hover .chevron
 {
  color:#ecba1f
}
.dropdown-header h3,
.dropdown-header .chevron
 {
  transition: all 0.3s ease-in-out;
}

.dropdown-body {
  padding: 15px 0;
  text-align: left;
}

.dropdown-body p {
  margin: 0;
  font-size: 16px;
}

.info-container{
  margin: auto;
}
.info-container h1{
  color: #ecba1f
}

.dropdown-open h3,
.dropdown-open .chevron
{
  color: #edbb20;
}
.dropdown-open{
  border-bottom: 1px solid #edbb20;
}

.dropdown-last{
  margin-bottom: 64px;
}
/* Chevron Styles */
.chevron {
  font-size: 18px;
  transition: transform 0.3s ease;
  color: white;
}

.chevron.open {
  transform: rotate(90deg); /* Rotate chevron when section is opened */
}

.info-footer{
  margin-top: 64px;
  margin-bottom: 92px;
  padding-bottom: 64px;
  color: #dea2f7
}








@media screen and (max-width: 1024px ) {
  .logo-content{
    width: 720px;
    height: 280px;
  }
}

.body-wrapper{
  background: rgba(29, 29, 29, 0.7) !important;
  backdrop-filter: blur(2px);
}

@media only screen and (max-width: 990px) {

  /* .body-container {
    top: 230px;
  } */

  .inline-link.big-link {
    font-size: 1.2em;
  }

  .info-subheader{
    text-align: left;
    padding: 0 15px;
    
  }

  .info-container{
    padding-top: 1px;
  }

  .body-wrapper {
    height: fit-content;
    /* min-height: 100vh; */
    z-index: 10;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .body-wrapper{
    backdrop-filter: none;
    background: none;
  }

  .hero-text-wrapper{
    backdrop-filter: blur(10px);
    padding: 8px;
    width: fit-content;
    margin: 0 auto;
  }

  .blob_zigzag {
    display: none;
  }

  .blob_doublestripe {
    display: none;
  }

  .blob_arrowsquiggle {
    display: none;
  }

  .blob_arrowstraight {
    display: none;
  }

  .blob_bigsquiggle {
    left: -60px !important;
  }

  .blob_dot {
    display: none;
  }

  .blob_bendlong {
    display: none;
  }

  .blob_circle_left {
    display: none;
  }
}


@media (orientation: landscape) and (max-width: 900px) {
  .logo-container {
    /* height: 110px !important;
    top: 30%; */
  }
  .hero-text-container{
    /* top: 36px; */
  }
  .about-bg{
    opacity: 0 !important;
  }
}


@media only screen and (max-width: 767px) {
  .App{
    position: fixed !important;
    width: 100vw;
  }

  section{
    /* padding: 0 5%; */
  }

  h2{
    font-size: 1.2em !important;
  }

  h3{
    font-size: 0.8em;
    line-height: 18px;

  }

  .links-container{
    padding-right: 0;
  }
  
  .about-text-container {
    width: 100% !important;
    padding: 0 !important;
  }

  .blob-container-left,
  .blob-container-right {
    transform: translatey(-50%) scale(0.5);
  }

  .blob_snowflake{
    top: -44px;
  }

  .body-container {
    /* top: 160px; */
  }

  .blob_waves {
    display: none;
  }

  .blob_longsquiggle {
    display: none;
  }

  .blob_arrowbent {
    right: -44px !important;
    top: 190px !important;
  }

  .blob_dubbend {
    display: none;
  }

  .blob_doublezag {
    bottom: 231px !important;
    left: -54px !important;
    top: unset !important;
  }

  .blob_mellowsquiggle {
    bottom: 214px !important;
    right: 0px !important;
    top: unset !important;
  }
 
  .logo-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logo-content{
    width: 90%;
    height: 200px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
  }

  .hero-text-container-bottom{
    margin: auto;
    margin-top: 0;
    margin-bottom: 24px;
    padding: 0 5%;
    
    
  }
  .hero-text-container-bottom h3{
    padding: 8px;
  }

  .body-end {
    width: 100%;
  }

  .body-wrapper{
    padding-top: 0;
    margin: 0;
  }

  .dropdown-container{
    padding: 0;
    padding-bottom: 64px;
    backdrop-filter: blur(10px);
    width: 100%;
  }

  #info .container{
    padding: 0;
    padding-bottom: 64px !important; 
  }

  .dropdown-body{
    padding: 14px;
  }

  .nav-link-highlight{
    margin-bottom: 124px !important;
  }

  .info-footer{
    padding-bottom: 0;
    margin-bottom: 0;

  }
}


@media only screen and (max-width: 464px) {
  .hero-text-container{
    font-size: 0.8em;
    
  }
  .hero-text-container h2{
    margin: 0;
    
  }

  .hero-text-wrapper{
   flex-direction: column; 
  }

  .logo-content{
    height: 162px
  }
}