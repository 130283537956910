/* Contact Page Container */
.contact-page {
  height: 100vh;
  background-color: #1d1d1d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 72px;
}

/* Contact Form Container */
.contact-container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping for smaller screens */
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background: rgba(21, 21, 21, 0.8);
  padding: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 1;
  padding-top: 0;
  backdrop-filter: blur(2px);
}

.contact-container h1{
  color: #ecba1f
}

.contact-form-container{
  display: flex;
}

/* Text Container (for contact details or other info) */
.contact-text-container {
  flex: 1;
  padding: 20px;
  color: #f0f0f0;
  text-align: left;
  margin-top: 16px;
}
.contact-text-container h3 {
  line-height: 21px;
}

/* Form Container */
.form-container {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

/* Heading */
h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
  text-align: left;
  padding-left: 20px;
}

/* Form Styling */
form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: auto;
  
}

/* Form Group */
.form-group {
  margin-bottom: 15px;
}

/* Label Styling */
label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #f0f0f0;
  font-size: 1.1rem;
}

/* Input and Textarea Styling */
input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  background-color: #000;
  color: #f0f0f0;
  border: 1px solid #edbb20; /* Add a border for better contrast */
  border-radius: 4px;
  outline: none;
  margin-top: 8px;
  box-sizing: border-box;
}

textarea {
  height: 150px;
  resize: vertical;
}

/* Submit Button Styling */
button[type="submit"] {
  padding: 12px;
  background-color: #6a0dad;
  color: #fff;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
  width: 100%;
  max-width: 200px;
  align-self: flex-start;
}

button[type="submit"]:hover {
  background-color: #5e0cac;
}

/* Status Message Styling */
.status {
  margin-top: 15px;
  color: #fff;
  font-size: 1rem;
  text-align: center;
}

.form-field{
  text-align: left;
  margin-top: 24px;
}

/* Responsive Design for Smaller Screens */
@media screen and (max-width: 768px) {
  .contact-page{
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .contact-container {
      flex-direction: column;
      padding: 20px;
      padding-left: 2%;
      padding-right: 2%;
      padding-top: 0;
      margin-top: 68px;
  }

  .contact-container h1 {
    
    padding-left: 0px !important;
    
}
  
  h1 {
      font-size: 2rem;
  }

  input[type="text"],
  input[type="email"],
  textarea {
      font-size: 0.9rem;
  }

  button[type="submit"] {
      font-size: 1rem;
      padding: 10px;
      width: 100%;
  }

  .contact-text-container {
    order: 1;
    padding: 0;
    
  }

  .form-container{
    padding: 0;
  }

  .contact-form-container{
    flex-direction: column;
  }

  .contact-container h1{
    padding-left: 20px;
  }

 
}

/* Extra small screens */
@media screen and (max-width: 480px) {
  h1 {
      font-size: 1.8rem;
  }

  input[type="text"],
  input[type="email"],
  textarea {
      font-size: 0.85rem;
      padding: 10px;
  }

  button[type="submit"] {
      padding: 8px;
      font-size: 0.9rem;
  }
}
