li{
  text-align: left;
}
.venues-page{
    /* height: 100%;
    margin-bottom: 24px; */
}

/* Style for the overall page layout */
.venues-container {
    position: relative;
    padding: 24px 0 2em 0;
    background-color: #f0f0f0;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 112px;
    margin-bottom: 64px;
    /* text-align: center; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    background: rgba(21, 21, 21, 0.6);
    /* backdrop-filter: blur(4px); */
    /* background: rgba(255, 255, 255, 0.8); */
}

.venues-list-container{
  margin: 32px 24px 88px 24px

}

.venue{
  display: flex;
  margin-top: 88px;
  margin-bottom: 88px;
}  
.image-right{
  flex-direction: row-reverse;
}

.venue-accent{
  color: #edbb20;
  margin-bottom: 0;
}

.venue-title{
  padding-left: 0;
  margin-top: 4px;
  margin-bottom: 12px;
}

.venue-maps-link{
  margin-top: 12px;
}

.venue-description-container{
  /* background: rgba(0, 0, 0, 0.4); */
  backdrop-filter: blur(20px);
  padding: 2px 16px;
}

.venue-image{
  max-height: 558px;
  max-width: 54%;
  object-fit: cover;
}  
.venue-text{
  width: 100%;
}
.image-left .venue-text{
  text-align: left;
  padding-left: 20px;
}
.image-right .venue-text{
  text-align: right;
  padding-right: 20px;
}
.image-right .venue-title{
  text-align: right;
  
}
  /* Header for the page */
  .venues-header h1 {
    color: white;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }
  
  .venues-header h2 {
    color: #edbb20;
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  .venues-info{
    /* background: rgba(0, 0, 0, 0.4); */
  backdrop-filter: blur(20px);
  padding: 2px 16px;
  max-width: 600px;
    text-align: center;
    margin: auto;
    margin-top: 24px;
  }
  
  /* Section for venues pricing info */
  .venues-info h3 {
    color: #444;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .venues-list {
    list-style-type: none;
    padding: 0;
  }
  
  .venues-list li {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 1rem;
    margin: 0.5rem 0;
    /* border-radius: 8px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .venues-type {
    font-weight: bold;
    color: #333;
    padding-right: 10%;
  }
  
  .venues-price {
    color: #1D1D1D;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  /* Button link to the venues page */
  .venues-link-container {
    margin-top: 2rem;
  }
  
  .venues-link {
    display: inline-block;
    padding: 1rem 2rem;
    background-color:  #9b17cb;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    letter-spacing: 2px;
  }
  
  .venues-link:hover {
    /* background-color: #e65a00; */
  }

  .venues-info-container p {
    text-align: left;
    color: #1D1D1D;
  }
  .venues-info-container a {
    /* text-align: left; */
    color: #1D1D1D;
  }

  .venues-list-container .venue:first-of-type{
    margin-top: 0;
  }
  
  @media screen and (max-width: 768px) {
    .venues-page{
      height: fit-content;
      /* padding-bottom: 64px; */
    }

    .venues-container{
      position: relative;
      margin-top: 64px;
      margin-bottom: 0;
    }

    .venues-header h1{
      margin-top: 0;
    }

    .venues-header{
      margin-bottom: 24px;
    }
    
    .venues-list-container{
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .venue-description-container{
      margin: 0px -24px;
      padding: 2px 24px;
    }

    .venue-image{
      max-width: 100%;
    }
    .venue{
      flex-direction: column;
      margin-top: 24px;
    }

    .venue-text{
      padding-left: 0 !important;
      text-align: left !important;
    }
    .venue-title{
      text-align: left !important;
    }

    .venue-link-container{
      margin-bottom: 32px;
    }


  }